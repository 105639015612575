import React, { useState } from "react"
import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  CardMedia,
  Box,
  Link as MaterialUiLink,
} from "@material-ui/core"
import { Link as GatsbyLink } from "@3nvi/gatsby-theme-intl"

interface MyCardProps {
  content: string
  title: string
  imgSrc: string
}
const MyCard: React.FC<MyCardProps> = ({ title, content, imgSrc }) => {
  const [hover, setHover] = useState(false)

  return (
    <Card
      style={{ cursor: "pointer", height: "100%" }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      raised={hover}
    >
      <CardContent
        style={{ flexDirection: "row", display: "flex", height: "100%" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flex={1}
          margin={1}
        >
          <CardMedia
            component="img"
            style={{ height: "100%", maxHeight: "170px", objectFit: "contain" }}
            image={imgSrc}
            alt={title}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flex={1}
        >
          <Typography variant="h5">{title}</Typography>
          <Typography>{content}</Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

interface WebsiteCardProps {
  content: string
  title: string
  to?: string
  href?: string
  imgSrc: string
}
const WebsiteCard: React.FC<WebsiteCardProps> = ({
  content,
  title,
  to,
  imgSrc,
  href,
}) => {
  if (to) {
    return (
      <GatsbyLink to={to} style={{ textDecoration: "none" }}>
        <MyCard content={content} title={title} imgSrc={imgSrc} />
      </GatsbyLink>
    )
  } else if (href) {
    return (
      <MaterialUiLink href={href} underline="none">
        <MyCard content={content} title={title} imgSrc={imgSrc} />
      </MaterialUiLink>
    )
  } else {
    return <MyCard content={content} title={title} imgSrc={imgSrc} />
  }
}

export default WebsiteCard
