import React from "react"

interface VideoProps {
  videoSrcURL: string
  videoTitle: string
}
const Video: React.FC<VideoProps> = ({ videoSrcURL, videoTitle, ...props }) => (
  <div
    style={{
      position: "relative",
      paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
    }}
  >
    <iframe
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
      }}
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      // @ts-ignore
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video
