import React from "react"

import { useTranslation } from "@3nvi/gatsby-theme-intl"
import { I18N_NAMESPACES } from "../i18next/i18n"
import {
  Typography,
  Grid,
  Box,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  List,
  Card,
  ThemeOptions,
} from "@material-ui/core"
import WebsiteCard from "./website-card"
import { DONOTSKIP_URLS } from "@arnaud-cortisse/donotskip-core"

import CoachApp from "../icons/coach_app.svg"
import ClientApp from "../icons/client_app.svg"
import ProgramsApp from "../icons/programs_app.svg"
import { ROUTES } from "../routes"
import Video from "./video"
import { useTheme } from "@material-ui/styles"

interface Hero {
  siteTitle: string
}
const HeroMain: React.FC<Hero> = ({ siteTitle }) => {
  const { t: tCommon } = useTranslation(I18N_NAMESPACES.COMMON)
  const theme = useTheme<ThemeOptions>()

  return (
    <Box
      style={{
        justifyContent: "center",
        flexDirection: "column",
        marginTop: "1rem",
        flex: 1,
      }}
    >
      <Typography variant="h3" style={{ textAlign: "center" }}>
        {siteTitle}
      </Typography>
      <Typography variant="subtitle1" style={{ textAlign: "center" }}>
        {tCommon("Never skip another workout")}
      </Typography>
      <Box marginY={4} />
      <Card
        style={{
          paddingTop: 30,
          paddingBottom: 30,
          paddingRight: 10,
          paddingLeft: 10,
          textAlign: "center",
        }}
      >
        <Typography variant="h3">
          {tCommon("Wanna share your workout program?")}
        </Typography>
        <Box m={4} />
        <Typography
          variant="h6"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {tCommon("DoNotSkip Pitch")}
        </Typography>
        <Box m={4} />
        <Typography variant="h5">{tCommon("Main title")}</Typography>
      </Card>
      <Grid
        container
        direction="row"
        spacing={2}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
        alignItems="stretch"
      >
        <Grid item xs={12} sm={6} md={4}>
          <WebsiteCard
            title={tCommon("Coaches")}
            content={tCommon("I want to create and share my own programs")}
            to={ROUTES.COACHES}
            imgSrc={CoachApp}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <WebsiteCard
            title={tCommon("Programs")}
            content={tCommon("I want to find a new workout")}
            href={DONOTSKIP_URLS.DONOTSKIP_PROGRAMS}
            imgSrc={ProgramsApp}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <WebsiteCard
            title={tCommon("App")}
            content={tCommon(
              "I want to train with my favorite coaches' programs"
            )}
            to={ROUTES.MOBILE_APP}
            imgSrc={ClientApp}
          />
        </Grid>
      </Grid>
      <Box my={2} />
      <Video
        videoSrcURL="https://www.youtube.com/embed/Ul6Tkf-h4ic"
        videoTitle="DoNotSkip Demo"
      />
      <Box my={2} />
      <Paper>
        <Box p={1}>
          <Typography variant="h6">
            {tCommon("DoNotSkip consists of three products")}
          </Typography>
          <List>
            <ListItem divider>
              <ListItemText primary={tCommon("DoNotSkip Coaches desc")} />
            </ListItem>
            <ListItem divider>
              <ListItemText primary={tCommon("DoNotSkip Programs desc")} />
            </ListItem>
            <ListItem>
              <ListItemText primary={tCommon("DoNotSkip App desc")} />
            </ListItem>
          </List>
        </Box>
      </Paper>
      <Box m={2} />
    </Box>
  )
}

export default HeroMain
