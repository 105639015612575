import React from "react"
import Layout from "../components/layout"
import HeroMain from "../components/hero-main"
import MyAppBar from "../components/my-app-bar"
import { Container } from "@material-ui/core"
import Footer from "../components/footer"
import { useStaticQuery, graphql } from "gatsby"
import headerImage from "../images/header.png"

const IndexPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout>
      <div
        style={{
          backgroundImage: `url(${headerImage})`,
          position: "absolute",
          top: 0,
          zIndex: -5,
          height: "100%",
          width: "100%",
          opacity: 0.5,
        }}
      />
      <MyAppBar siteTitle={"DoNotSkip"} />
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Container
          maxWidth="md"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HeroMain siteTitle={siteTitle} />
          <Footer siteTitle={siteTitle} />
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage
